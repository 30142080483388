<template>
  <div>
    <FullDialog
      dense
      :dialog="calendarDialog"
      v-on:close="calendarDialog = false"
      :back-route="{ name: 'admin.dashboard' }"
    >
      <template v-slot:title>
        <v-toolbar height="34" flat class="bg-transparent py-0">
          <v-flex class="my-auto">
            <v-menu bottom>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="mr-5 font-level-3-bold d-inline-block --text fw-600"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ resourceType }}
                  <v-icon color="">mdi-menu-down</v-icon>
                </div>
              </template>
              <v-list style="min-width: 200px">
                <v-list-item
                  @click="resourceType = 'Visit'"
                  class="border-bottom"
                >
                  <v-list-item-title>Visit</v-list-item-title>
                </v-list-item>
                <v-list-item @click="resourceType = 'Delivery'">
                  <v-list-item-title>Delivery</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              color="cyan white--text"
              tile
              depressed
              class="mr-1 rounded"
              v-on:click="getToday()"
            >
              Today
            </v-btn>
            <v-btn
              color="cyan white--text"
              tile
              depressed
              small
              outlined
              width="30"
              min-width="30"
              height="26"
              class="mr-1 rounded"
              v-on:click="preview()"
            >
              <v-icon>mdi-menu-left</v-icon>
            </v-btn>
            <v-btn
              color="cyan white--text"
              tile
              depressed
              small
              outlined
              width="30"
              min-width="30"
              height="26"
              class="mr-1 rounded"
              v-on:click="next()"
            >
              <v-icon>mdi-menu-right</v-icon>
            </v-btn>
            <div
              class="mx-5 font-level-3-bold d-inline-block cyan--text fw-600"
              style="min-width: 150px; font-size: 18px"
            >
              {{ toolbarTitle }}
            </div>
          </v-flex>
          <v-flex class="d-flex justify-content-start align-items-center">
            <div
              class="mx-5 font-level-3-bold d-inline-block cyan--text fw-600"
              style=""
            >
              Filters
            </div>
            <v-flex class="mr-2" style="max-width: 12rem">
              <DatePicker
                outlined
                depressed
                hide-details
                placeholder="Select Date"
                class-name="mt-0"
              >
              </DatePicker>
            </v-flex>
            <v-flex class="mr-2" style="max-width: 12rem">
              <v-autocomplete
                :items="statusList"
                v-model="status"
                hide-details
                clearable
                flat
                solo
                dense
                placeholder="Select Status"
                color="blue darken-4"
                item-color="blue darken-4"
                item-text="text"
                item-value="status"
                max-width="230px"
                min-height="26px"
                height="26px"
              >
                <!-- <template v-slot:selection="{ item, index }">
              <span class="text-truncate" v-if="index === 0">{{
                item.text
              }}</span>
              <span v-if="index === 1" class="ml-2 grey--text text-caption">
                (+{{ status.length - 1 }} more)
              </span>
            </template> -->
                <template v-slot:item="{ item }">
                  <v-list-item-action class="mr-0">
                    <v-chip
                      style="height: 16px !important; width: 16px; padding: 0"
                      :color="item.color"
                    >
                    </v-chip>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-capitalize font-weight-500 font-size-16"
                      >{{ item.text }}</v-list-item-title
                    >
                  </v-list-item-content>
                  <v-list-item-action
                    class="align-self-center"
                    v-if="status == item.status"
                  >
                    <v-icon color="cyan">mdi-check</v-icon>
                  </v-list-item-action>
                </template>
              </v-autocomplete>
            </v-flex>
            <v-flex class="mr-2" style="max-width: 12rem">
              <v-autocomplete
                :items="customerList"
                v-model="customer"
                hide-details
                clearable
                flat
                solo
                dense
                placeholder="Select Customer"
                color="blue darken-4"
                item-color="blue darken-4"
                item-text="display_name"
                item-value="id"
                style="max-width: 230px"
              >
                <!-- <template v-slot:selection="{ item }">
              <div class="text-truncate">{{ item.display_name }}</div>
            </template> -->
              </v-autocomplete>
            </v-flex>
          </v-flex>
          <v-flex class="d-flex justify-content-end">
            <v-btn
              color="cyan white--text"
              tile
              depressed
              class="mr-1 rounded"
              v-on:click="dayView()"
              :outlined="currentView != 'resourceTimelineDay'"
            >
              Day
            </v-btn>
            <v-btn
              color="cyan white--text"
              tile
              depressed
              class="mr-1 rounded"
              v-on:click="weekView()"
              :outlined="currentView != 'resourceTimelineWeek'"
            >
              Week
            </v-btn>
            <v-btn
              color="cyan white--text"
              tile
              depressed
              class="mr-1 rounded"
              v-on:click="monthView()"
              :outlined="currentView != 'resourceTimelineMonth'"
            >
              Month
            </v-btn>
            <v-btn
              depressed
              tile
              outlined
              color="cyan"
              class="white--text ml-1 rounded"
              :to="{ name: 'admin.calendar' }"
            >
              Calendar
            </v-btn>
            <v-btn
              outlined
              width="30"
              min-width="30"
              height="26"
              color="grey darken-4"
              class="white--text ml-3 rounded"
              @click="toggleVisit = !toggleVisit"
            >
              <v-icon size="20">{{
                toggleVisit
                  ? "mdi-format-indent-increase"
                  : "mdi-format-indent-decrease"
              }}</v-icon>
            </v-btn>
          </v-flex>
        </v-toolbar>
      </template>
      <template v-slot:body>
        <div class="resource-calendar-container">
          <div class="resource-calender d-flex">
            <div class="calender-wrapper">
              <FullCalendar ref="fullCalendar" :options="calendarOptions" />
              <div
                class="legends d-flex flex-wrap align-center justify-content-start my-2"
              >
                <div
                  class="px-2 mr-3 py-1 d-flex align-center"
                  v-for="(row, index) in [
                    { text: 'New', color: 'cyan' },
                    { text: 'In-Progress', color: 'orange' },
                    { text: 'Overdue', color: 'red' },
                  ]"
                  :key="index"
                  :style="`border-color:${row.color};`"
                >
                  <v-icon size="15" :color="row.color" class="mr-1"
                    >mdi-circle</v-icon
                  >
                  <span class="text-black">{{ row.text }}</span>
                </div>
              </div>
            </div>
            <div class="sidebar-event" v-show="toggleVisit">
              <div class="listing-contents">
                <div class="position-sticky px-3 mb-3">
                  <div class="d-flex py-1">
                    <h5
                      class="my-auto py-0 pl-2"
                      style="border-color: #a3a3a3 !important"
                    >
                      {{ filterType }}
                    </h5>
                    <v-menu offset-y v-if="false">
                      <template v-slot:activator="{ on, attrs }">
                        <h5
                          class="my-auto py-0 pl-2 cursor-pointer"
                          style="border-color: #a3a3a3 !important"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ filterType }}
                          <!-- <v-icon size="28">mdi-menu-down</v-icon> -->
                        </h5>
                      </template>
                      <v-list>
                        <v-list-item-group color="primary">
                          <v-list-item
                            link
                            @click="filterType = 'Assigned Visit'"
                            v-on:change="getVisitList('assigned')"
                          >
                            <v-list-item-title
                              class="fw-600 d-flex align-center"
                            >
                              <!--  <span
                          class="white--text mr-2 rounded-circle green d-flex justify-center align-center"
                          style="height: 22px; width: 22px"
                          >23</span
                        > -->
                              Assigned Visit</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item
                            link
                            @click="filterType = 'UnAssigned Visit'"
                            v-on:change="getVisitList('ussigned')"
                          >
                            <v-list-item-title
                              class="fw-600 d-flex align-center"
                            >
                              <!-- <span
                          class="white--text mr-2 rounded-circle blue d-flex justify-center align-center"
                          style="height: 22px; width: 22px"
                          >23</span
                        > -->
                              UnAssigned Visit</v-list-item-title
                            >
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
                    <v-spacer></v-spacer>
                    <v-btn
                      fab
                      icon
                      small
                      @click="showCalendarFilter = !showCalendarFilter"
                    >
                      <v-icon>mdi-calendar-check-outline</v-icon>
                    </v-btn>
                    <v-btn fab icon small min-width="30" width="30">
                      <v-icon>mdi-filter</v-icon>
                    </v-btn>
                  </div>
                  <div v-if="showCalendarFilter" class="mb-2">
                    <v-date-picker
                      full-width
                      no-title
                      color="green lighten-1"
                      v-model="datePicker"
                      :event-color="(date) => (date[9] % 2 ? 'red' : 'yellow')"
                    ></v-date-picker>
                    <!-- :events="functionEvents" -->
                  </div>
                </div>
                <v-flex class="mb-3 px-3 white">
                  <v-autocomplete
                    :items="engineerList"
                    v-model="engineers"
                    hide-details
                    clearable
                    flat
                    solo
                    dense
                    prepend-inner-icon="mdi-magnify"
                    placeholder="Select Engineer"
                    color="blue darken-4"
                    item-color="blue darken-4"
                    item-text="display_name"
                    item-value="id"
                    class="pa-0"
                  >
                    <!-- <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="text-truncate d-inline-block">{{
                  item.display_name
                }}</span>
                <span v-if="index === 1" class="ml-2 grey--text text-caption">
                  (+{{ engineer.length - 1 }} more)
                </span>
              </template> -->
                    <template v-slot:item="{ item }">
                      <v-list-item-avatar
                        class="mr-1"
                        height="30"
                        width="30"
                        min-width="30"
                        color="grey lighten-2 grey--text"
                      >
                        <span class="fw-500">
                          {{ item.display_name.slice(0, 2).toUpperCase() }}
                        </span>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-capitalize font-weight-500 font-size-16"
                          >{{ item.display_name }}</v-list-item-title
                        >
                      </v-list-item-content>
                      <v-list-item-action
                        class="align-self-center"
                        v-if="engineer == item.id"
                      >
                        <v-icon color="cyan">mdi-check</v-icon>
                      </v-list-item-action>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <div class="px-3">
                  <div
                    class="list-items fc-event border"
                    v-for="item in visit_list"
                    :key="item.id"
                    :data-set="JSON.stringify(item)"
                    :style="`background-color: ${getBgColor(item.status)}`"
                  >
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <span
                        class="fw-700 blue--text text-darken-4"
                        style="font-size: 16px"
                        >#{{ item.barcode }}</span
                      >
                      <div>
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              class="visit-date d-flex align-items-center mr-2"
                              v-on="on"
                              v-bind="attrs"
                            >
                              <v-icon
                                size="13"
                                color="green darken-4"
                                class="mr-1"
                                >mdi-alarm</v-icon
                              >
                              <span class="green--text fw-500 font-size-11">{{
                                formatDateTime(item.started_at)
                              }}</span>
                            </div>
                          </template>
                          <span>Start date</span>
                        </v-tooltip>
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              class="visit-date d-flex align-items-center mr-2"
                              v-on="on"
                              v-bind="attrs"
                            >
                              <v-icon
                                size="13"
                                color="red darken-4"
                                class="mr-1"
                                >mdi-alarm</v-icon
                              >
                              <span class="red--text fw-500 font-size-11">{{
                                formatDateTime(item.finished_at)
                              }}</span>
                            </div>
                          </template>
                          <span>End date</span>
                        </v-tooltip>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="flex-grow-1">
                        <div class="visit-title">
                          <span
                            class="text-truncate d-block fw-500"
                            style="font-size: 13px"
                            >{{ item.title }}</span
                          >
                        </div>
                        <div class="">
                          <span style="color: #63636c"
                            >abc -1235 758468568 jhj4144 565658 Singapore</span
                          >
                        </div>
                      </div>
                      <div class="action-side">
                        <div>
                          <v-icon
                            size="18"
                            class=""
                            :color="
                              getPriorityColor(
                                item.ticket_calendar.priority_text
                              )
                            "
                          >
                            mdi-circle
                          </v-icon>
                        </div>
                        <!-- <div>
                    <v-icon size="18" class="my-1"> mdi-flag</v-icon>
                  </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </FullDialog>
    <DetailDrawer
      :drawer="open_detail"
      v-on:close="open_detail = false"
    ></DetailDrawer>
  </div>
</template>
<script>
import DetailDrawer from "@/view/pages/calendar/DetailDrawer";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import DatePicker from "@/view/components/DatePicker";
//import ObjectPath from "object-path";
import moment from "moment-timezone";
import CommonMixin from "@/core/plugins/common-mixin";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
import { GET, QUERY } from "@/core/services/store/request.module";
/* import APIService from "@/core/services/api.service"; */
import FullDialog from "@/view/components/FullDialog2.vue";

export default {
  mixins: [CommonMixin],
  name: "Resource-calender",
  components: {
    FullDialog,
    FullCalendar,
    DetailDrawer,
    DatePicker,
  },
  data() {
    return {
      resourceType: "Visit",
      calendarDialog: true,
      toggleVisit: true,
      showCalendarFilter: false,
      filterType: "UnAssigned Visit",
      calendarFilterType: "Assigned Visit",
      count: 0,
      open_detail: false,
      payload_data: null,
      status: "0",
      customer: "0",
      engineers: "0",
      toolbarTitle: null,
      currentView: "resourceTimelineDay",
      labelFormat: [{ hour: "numeric" }],
      calendarOptions: {
        schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
        plugins: [resourceTimelinePlugin, interactionPlugin, dayGridPlugin],
        headerToolbar: {
          left: "prev,next",
          center: "title",
          right:
            "resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth",
        },
        timeZone: process.env.VUE_APP_TIMEZONE,
        initialView: "resourceTimelineDay",
        slotLabelFormat: this.labelFormat,
        slotLabelContent: this.formatLabel,
        aspectRatio: 1.8,
        resourceAreaWidth: "300px",

        editable: true,
        droppable: true,
        eventOverlap: false,

        eventClick: this.handleEventClick,
        resourceLabelDidMount: this.mountResourceLabel,
        eventDidMount: this.mountEventData,
        eventReceive: this.customEventReceive,
        drop: this.dropFunction,
        eventDrop: this.eventDrop,
        eventResize: this.eventResize,

        resourceAreaHeaderContent: {
          html: '<div class="text-left mb-1">Engineers</div><div class="position-relative"><input type="text" placeholder="Search Engineers..." class="pr-10 gt-input search-input"  /><div class="magnify"><i class="mdi mdi-magnify v-icon"></i></div></div>',
        },
        resourceAreaHeaderClassNames: "engineers-search",
        resources: [],
        events: [],
        fixedMirrorParent: document.body,
      },
      resource_name: [],
      event_lists: [
        {
          resourceId: "1",
          eventType: "task",
          title: "event 1",
          start: "2023-09-26 16:00:00",
          end: "2023-09-26 22:00:00",
          visitId: "Visit123",
          startDate: "2023-09-26 16:00:00",
          endDate: "2023-09-26 22:00:00",
          bg_color: "blue",
          classNames: "custom-event blue lighten-3",
        },
        {
          resourceId: "2",
          eventType: "task",
          title: "event 3",
          start: "2023-09-29 10:00:00",
          end: "2023-09-29 12:00:00",
          startDate: "2023-09-29 10:00:00",
          visitId: "Visit123",
          endEnd: "2023-09-29 12:00:00",
          bg_color: "blue",
          classNames: "custom-event blue lighten-3",
        },
        {
          resourceId: "1",
          eventType: "visit",
          title: "event d",
          start: "2023-09-25 10:00:00",
          end: "2023-09-25 12:00:00",
          startDate: "2023-09-25 10:00:00",
          visitId: "Visit123",
          endDate: "2023-09-25 12:00:00",
          bg_color: "orange",
          classNames: "custom-event orange lighten-3",
        },
        {
          resourceId: "2",
          eventType: "visit",
          title: "event f",
          start: "2023-09-26 10:00:00",
          end: "2023-09-27 12:00:00",
          visitId: "Visit123",
          startDate: "2023-09-26 10:00:00",
          endDate: "2023-09-27 12:00:00",
          bg_color: "orange",
          classNames: "custom-event orange lighten-3",
        },
        {
          resourceId: "1",
          eventType: "visit",
          title: "event 4",
          start: "2023-09-27 10:00:00",
          end: "2023-09-28 12:00:00",
          visitId: "Visit123",
          startDate: "2023-09-27 10:00:00",
          endDate: "2023-09-28 12:00:00",
          bg_color: "orange",
          classNames: "custom-event orange lighten-3",
        },
      ],
      visit_list: [],

      statusList: [
        {
          type: 12,
          text: "All Visits",
          value: "all",
          description: "All Visits",
          activity_text: "All Visits",
          color: "cyan",
          textcolor: "white",
          headercolor: "primary",
          order: 1,
          status: 0,
          top_visible: 1,
        },
        {
          type: 12,
          text: "Open",
          value: "open",
          description: "Open Visits",
          activity_text: "Open",
          color: "blue darken-1",
          textcolor: "white",
          headercolor: "primary",
          order: 2,
          status: 1,
          top_visible: 1,
        },
        {
          type: 12,
          text: "In-Progress",
          value: "in-progress",
          description: "In-Progress Visits",
          activity_text: "In-Progress",
          color: "yellow accent-4",
          textcolor: "blue-grey darken-4",
          headercolor: "primary",
          order: 3,
          status: 2,
          top_visible: 1,
        },
        {
          type: 12,
          text: "Hold",
          value: "hold",
          description: "Hold Visits",
          activity_text: "Hold",
          color: "orange darken-4",
          textcolor: "white",
          headercolor: "primary",
          order: 4,
          status: 3,
          top_visible: 1,
        },
        {
          type: 12,
          text: "Completed",
          value: "completed",
          description: "Completed Visits",
          activity_text: "Completed",
          color: "green darken-1",
          textcolor: "white",
          headercolor: "primary",
          order: 5,
          status: 4,
          top_visible: 1,
        },
        {
          type: 12,
          text: "Cancelled",
          value: "cancelled",
          description: "Cancelled Visits",
          activity_text: "Cancelled",
          color: "red lighten-1",
          textcolor: "white",
          headercolor: "primary",
          order: 6,
          status: 5,
          top_visible: 1,
        },
      ],
      customerList: [
        {
          id: 1,
          display_name: "Business Thrust Techsoft Pvt Ltd.",
        },
        {
          id: 2,
          display_name: "jeny",
        },
        {
          id: 3,
          display_name: "Ethan Alex",
        },
      ],
      engineerList: [
        {
          id: 1,
          display_name: "John Doe",
        },
        {
          id: 2,
          display_name: "john",
        },
        {
          id: 3,
          display_name: "Mandarin Mandarin",
        },
        {
          id: 4,
          display_name: "Ang peng Siong",
        },
      ],
    };
  },
  methods: {
    next() {
      let calendarApi = this.$refs.fullCalendar.getApi();
      calendarApi.next();
      this.toolbarTitle = calendarApi.view.title;
    },
    preview() {
      let calendarApi = this.$refs.fullCalendar.getApi();
      calendarApi.prev();
      this.toolbarTitle = calendarApi.view.title;
    },
    getToday() {
      let calendarApi = this.$refs.fullCalendar.getApi();
      calendarApi.today();
      this.toolbarTitle = calendarApi.view.title;
    },
    dayView() {
      let calendarApi = this.$refs.fullCalendar.getApi();
      calendarApi.changeView("resourceTimelineDay");
      this.toolbarTitle = calendarApi.view.title;
      this.currentView = calendarApi.view.type;
      this.calendarOptions.slotLabelFormat = [{ hour: "numeric" }];
    },
    weekView() {
      let calendarApi = this.$refs.fullCalendar.getApi();
      calendarApi.changeView("resourceTimelineWeek");
      this.toolbarTitle = calendarApi.view.title;
      this.currentView = calendarApi.view.type;
      this.calendarOptions.slotLabelFormat = [
        { day: "2-digit", weekday: "long", week: "long" },
        { hour: "numeric" },
      ];
    },
    monthView() {
      let calendarApi = this.$refs.fullCalendar.getApi();
      calendarApi.changeView("resourceTimelineMonth");
      this.toolbarTitle = calendarApi.view.title;
      this.currentView = calendarApi.view.type;
      this.calendarOptions.slotLabelFormat = [
        { week: "long" },
        { day: "2-digit", weekday: "short" },
      ];
    },
    formatLabel(el) {
      const weekDayArr = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
      const d = new Date(el.date);
      const weekDay = d.getDay();
      const DayDate = d.getDate();
      if (el.view.type == "resourceTimelineMonth" && el.level == 1) {
        return {
          html: `
          <div class="text-center ${
            weekDay == 0 || weekDay == 6 ? "grey--text text--darken-4" : ""
          }">
            <div>${weekDayArr[weekDay]}</div>
            <div class="bt-day">${DayDate}</div>
          </div>
          `,
        };
      }
    },
    mountEventData(info) {
      this.$nextTick(() => {
        const extended = info.event._def.extendedProps;
        if (extended.visitId != undefined) {
          let infoElement = document.createElement("div");
          const startTime = /* extended.startDate; */ moment(
            extended.startDate,
            "YYYY-MM-DD h:mm:ss"
          ).format("LT");
          const endTime = /* extended.endDate;  */ moment(
            extended.endDate,
            "YYYY-MM-DD h:mm:ss"
          ).format("LT");
          console.log(info.event, "mountEventData");
          const bt_elem = `<div class="d-flex">
                <div class="pl-1" style="color:#333;white-space: nowrap;">
                  <b>${info.event.title}</b>
                  <div>
                  <span> ${startTime} - ${endTime}</span>
                </div>
                  <!-- <div class="time text-truncate" style="font-size:16px;color:#333">Globex Corporation</div> 
                  <div class="event-type px-2 ${info.event._def.extendedProps.bg_color} darken-3 rounded d-inline-block">${info.event._def.extendedProps.eventType}</div>-->
                </div>
              </div>`;
          infoElement.innerHTML = bt_elem; //info.event._def.extendedProps.visitId;
          infoElement.classList.add("fc-event-id");
          let elementNode = info.el.querySelector(".fc-event-title-container");
          elementNode.prepend(infoElement);
        }
      });
    },
    mountResourceLabel(info) {
      let profile_name = document.createElement("div");
      let profile_image = document.createElement("img");
      let total_count = document.createElement("div");

      profile_name.innerHTML =
        info.resource._resource.extendedProps.designation;
      const avatar = info.resource._resource.extendedProps.avatar;
      profile_image.setAttribute(
        "src",
        avatar ? avatar : "/media/default/no-profile-image.png"
      );

      total_count.setAttribute("class", "resource_user_total");
      const el = `<div class="v--new cyan" title="New">23</div>
                <div class="v--inprogress orange" title="In-Progress">
                  3
                </div>
                <div class="v--overdue red" title="Over Due">232</div>
                `;
      total_count.innerHTML = el;
      if (info.resource._resource.extendedProps.child) {
        profile_image.setAttribute("class", "resource_user_image ml-5");
        profile_name.setAttribute(
          "class",
          "resource_user_designation font-size-13 pl-72"
        );
      } else {
        profile_image.setAttribute("class", "resource_user_image mr-3");
        profile_name.setAttribute(
          "class",
          "resource_user_designation font-size-13 pl-52"
        );
      }
      let elementNode = info.el.querySelector(".fc-datagrid-cell-cushion");
      //let StatusElementNode = info.el.querySelector(".fc-datagrid-cell-main");
      elementNode.style.borderColor = `${info.resource._resource.extendedProps.status_color}`;
      elementNode.classList.add("h-100");
      elementNode.prepend(profile_image);
      elementNode.append(profile_name);
      elementNode.append(total_count);
    },
    dragableEvent() {
      let containerEl = document.querySelector(".listing-contents");
      if (containerEl) {
        new Draggable(containerEl, {
          itemSelector: ".fc-event",
          eventData: this.getPayload,
        });
      }
    },
    getPayload(eventEl) {
      this.payload_data = JSON.parse(eventEl.getAttribute("data-set"));
      return this.payload_data;
    },
    customEventReceive() {
      // this.$nextTick(() => {
      //   const extended = info.event._def.extendedProps;
      //   const resourceId = info.event._def.resourceIds[0]
      //   const eventData = {
      //       resourceId: resourceId,
      //       title: extended.barcode,
      //       start: extended.finished_at,
      //       end: extended.started_at,
      //       visitId: extended.barcode,
      //       bg_color: 'blue',
      //       classNames: `custom-event h-100 blue`,
      //     };
      // })
    },
    eventDrop(info) {
      console.log(info, "eventDrop");
    },
    eventResize(info) {
      console.log(info, "eventResize");
    },
    dropFunction(data) {
      this.$nextTick(() => {
        console.log(data, "eventDrop");
        const payload = this.payload_data;
        const eventData = {
          resourceId: data.resource._resource.id,
          title: payload.title,
          start: data.dateStr,
          end: data.dateStr,
          visitId: payload.id,
          bg_color: payload.status_color,
          classNames: `custom-event h-100 ${payload.status_color}`,
        };
        this.event_lists.push(eventData);
      });
    },
    // eventDrop(param) {
    //   try {
    //     const event = param.event;

    //     const new_resource = param.newResource;

    //     const old_resource = param.oldResource;

    //     const extended_props = event.extendedProps;

    //     const is_all_day = this.lodash.toSafeInteger(event.allDay);

    //     const params = {
    //       all_day: is_all_day,
    //       date: is_all_day ? event.startStr.substr(0, 19) : null,
    //       visit: extended_props.visitId,
    //       engineer: new_resource.id,
    //       del_engineer: old_resource.id,
    //       started_at: is_all_day ? null : event.startStr.substr(0, 19),
    //       finished_at: is_all_day ? null : event.endStr.substr(0, 19),
    //     };

    //     if (!event.allDay && !event.end) {
    //       params.finished_at = moment(event.startStr)
    //         .add(1, "hour")
    //         .format("YYYY-MM-DDTHH:mm:ss");
    //     }

    //     APIService.patch("resource-calendar/event-dropped", params)
    //       .catch((error) => {
    //         this.logError(error);
    //       })
    //       .finally(() => {
    //         this.refetchEvents();
    //       });
    //   } catch (error) {
    //     this.logError(error);

    //   }
    // },
    handleEventClick() {
      this.open_detail = true;
      //console.log(info.event.title);
    },
    getColor(data) {
      if (data == 1) {
        return "blue";
      } else if (data == 2) {
        return "purple";
      } else if (data == 3) {
        return "red";
      } else if (data == 4) {
        return "green";
      } else if (data == 5) {
        return "red";
      } else if (data == 6) {
        return "cyan";
      }
    },
    getPriorityColor(data) {
      if (data == "Low") {
        return "green";
      } else if (data == "Medium") {
        return "orange";
      } else if (data == "High") {
        return "red";
      }
    },
    getBgColor(data) {
      if (data == 1) {
        return "#E3F2FD  !important";
      } else if (data == 2) {
        return "#f9f9ce !important";
      } else if (data == 3) {
        return "#f3e6cb !important";
      } else if (data == 4) {
        return "#E8F5E9 !important";
      } else if (data == 5) {
        return "#FFEBEE !important;";
      } else if (data == 6) {
        return "#d9fffd !important";
      }
    },
    formatDateTimeStart(row) {
      return moment(row).format("hh:mm A");
    },
    formatetimedata(row) {
      return moment(row).format("hh:mm A");
    },
    getVisitList(/* datePicker,  */ type) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "visit-list",
              data: {
                /* date: datePicker, */
                type: type,
                /* search: this.search, */
              },
            })
            .then(({ data }) => {
              this.visit_list = data;
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getResource() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, {
              url: "resource-calendar/resources",
            })
            .then((data) => {
              this.resource_name = data;
              this.calendarOptions.resources = this.resource_name;
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  mounted() {
    let calendarApi = this.$refs.fullCalendar.getApi();
    this.toolbarTitle = calendarApi.view.title;
    this.calendarOptions.events = this.event_lists;
    this.getResource();
    this.getVisitList("ussigned");
    this.dragableEvent();
  },
};
</script>
<style scoped></style>
